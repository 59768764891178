import React, { useState, Suspense, useRef, useEffect } from "react"
import { Canvas, extend, useFrame, useLoader, useThree } from "@react-three/fiber"
import { Environment, Html, useProgress } from "@react-three/drei"
import { useGLTF } from "../src/hooks/useGLTF"
import { EffectComposer, N8AO, SSAO, Bloom, Noise, ToneMapping, Vignette } from "@react-three/postprocessing"
import { useTranslation } from "react-i18next"
import { ToneMappingMode } from "postprocessing"
import { useReseller } from "../src/context/resellerContext"
import LanguageOverlay from "../src/components/languageOverlay"
import PrivacyOverlay from "../src/components/privacyOverlay"
import ResellerBar from "../src/components/resellerBar"
// import { InView, useInView } from "react-intersection-observer";
import { OrbitControls } from "../src/OrbitControls"
import { isMobile } from "react-device-detect"
import { RepeatWrapping, MeshPhysicalMaterial, DoubleSide, HalfFloatType, SRGBColorSpace } from "three"
import "../src/services/i18n"
import i18n from "i18next"
import { TextureLoader } from "three/src/loaders/TextureLoader"
import ModelConfig from "../src/modelConfig.json"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import * as THREE from "three"

extend({ OrbitControls })
export default function App() {
  let clickTaleTagInjected = false
  useEffect(() => {
    if (!window.location.search.includes("analytics=true")) {
      return
    }

    if (!window.clickTaleTagInjected) {
      !(function (d, t, u) {
        clickTaleTagInjected = true
        function injectTag() {
          var ns = d.createElementNS
          var a = ns ? ns.call(d, "http://www.w3.org/1999/xhtml", t) : d.createElement(t),
            s = d.getElementsByTagName(t)[0]
          a.async = true
          a.crossOrigin = "anonymous"
          a.type = "text/javascript"
          a.src = u
          s.parentNode.insertBefore(a, s)
        }
        if (d.readyState != "loading") {
          injectTag()
        } else {
          d.addEventListener("DOMContentLoaded", function () {
            setTimeout(injectTag, 0)
          })
        }
      })(document, "script", "https://cdnssl.clicktale.net/www33/ptc/b529813b-3dee-4cf4-a877-00cb7f37f47a.js")
    }
  }, [])
  const product = new URLSearchParams(window.location.search).get("product")
  const noZoom = new URLSearchParams(window.location.search).get("noZoom")
  const closeButton = new URLSearchParams(window.location.search).get("closeButton")
  const [color, setColor] = useState(0)
  const { t } = useTranslation()

  const config = ModelConfig[product]
  const [{ reseller, analytics, locale, r, catalina, hide }, dispatchReseller] = useReseller()
  const [loaded, setLoaded] = useState(false)
  const [idle, setIdle] = useState({ idle: false, target: null })
  const [update, setUpdate] = useState(true)
  const controls = useRef()
  const canvas = useRef()
  const [keyPressed, setKeyPressed] = useState(false)

  // THREE.ColorManagement.enabled = false

  useEffect(() => {
    window.history.replaceState(null, "", `/${i18n.language}${window.location.search}`)
  }, [i18n.language])

  const idleModel = (currentTarget) => {
    setIdle((idle) => ({
      ...idle,
      idle: true,
      target: currentTarget,
    }))
    setTimeout(function () {
      setIdle((idle) => ({
        ...idle,
        idle: false,
        target: currentTarget,
      }))
    }, 10)
  }

  useEffect(() => {
    if (hide.includes("1")) {
      for (let i = 0; i < colorItems.length; i++) {
        if (!hide.includes(i + 1)) {
          setColor(i)
          break
        }
      }
    }
  }, [hide])

  function downHandler({ key }) {
    if (key === "ArrowLeft" && !keyPressed) {
      setUpdate(false)
      controls.current.rotateLeft(5)
      controls.current.update()
      setKeyPressed(() => ({
        ...true,
      }))
    }
    if (key === "ArrowUp" && !keyPressed) {
      setUpdate(false)
      controls.current.rotateUp(5)
      controls.current.update()
      setKeyPressed(() => ({
        ...true,
      }))
    }
    if (key === "-" && !keyPressed) {
      setUpdate(false)
      controls.current.dollyIn(1.1)
      controls.current.update()
      setKeyPressed(() => ({
        ...true,
      }))
    }
    if (key === "+" && !keyPressed) {
      setUpdate(false)
      controls.current.dollyOut(1.1)
      controls.current.update()
      setKeyPressed(() => ({
        ...true,
      }))
    }
    if (key === "ArrowDown" && !keyPressed) {
      setUpdate(false)
      controls.current.rotateUp(-5)
      controls.current.update()
      setKeyPressed(() => ({
        ...true,
      }))
    }
    if (key === "ArrowRight" && !keyPressed) {
      setUpdate(false)
      controls.current.rotateLeft(-5)
      controls.current.update()
      setKeyPressed(() => ({
        ...true,
      }))
    }
  }
  const upHandler = ({ key }) => {
    if (key === "ArrowLeft" || "ArrowRight" || "ArrowDown" || "ArrowUp" || "-" || "+") {
      setUpdate(false)
      setKeyPressed(() => ({
        ...false,
      }))
    }
  }

  const clickHandler = () => {
    setUpdate(true)
  }
  useEffect(() => {
    window.addEventListener("click", clickHandler)
    window.addEventListener("keydown", downHandler)
    window.addEventListener("keyup", upHandler)
    return () => {
      window.removeEventListener("keydown", downHandler)
      window.removeEventListener("keyup", upHandler)
    }
  }, [])

  const LoadedModel = () => {
    const { scene } = useGLTF(`/${config.model}.glb`)
    const textures = config.dynamicTextures
    let textureMap = []
    if (config.dynamicTextures.length > 0) {
      textures.forEach(function (item, index) {
        item = useLoader(TextureLoader, `/${config.model}-${index}-${config.textureNames[color]}.jpg`)
        item.colorSpace = SRGBColorSpace

        item.flipY = false
        item.wrapS = RepeatWrapping
        item.wrapT = RepeatWrapping
        textureMap.push(item)
      })
    }

    useEffect(() => {
      scene.traverse(function (child) {
        if (child.material) {
          child.material.dithering = true
          child.material.toneMapped = false
          child.material.needsUpdate = true
          child.material.envMapIntensity = config.envmap[color]
        }
        if (child.name === "Plane") {
          // child.material.blending = THREE.MultiplyBlending

          child.material.opacity = product === "flex2" || product === "flex" || product === "slhome" ? 1 : 0.1
          child.material.toneMapped = false
          child.material.transparent = true
          child.material.needsUpdate = true
          // child.material.blending = THREE.MultiplyBlending
        }
        if (child.name.includes("BT_Logo")) {
          child.material.color = { r: 0.300000011920929, g: 0.300000011920929, b: 0.300000011920929 }
        }
        if (config.dynamicTextures.includes(child.name)) {
          const texture = textureMap[config.dynamicTexturesName[child.name]]
          texture.colorSpace = SRGBColorSpace
          child.material.dithering = true
          texture.wrapS = RepeatWrapping
          texture.wrapT = RepeatWrapping
          texture.flipY = false

          child.material.envMapIntensity = config.envmap[color]
          child.material.map = texture
        }
        if (product === "qcuhp") {
          if (child.name.includes("BT_Logo")) {
            if (color === 0 || color === 3) {
              child.material.color = { r: 0.100000011920929, g: 0.100000011920929, b: 0.100000011920929 }
            }
          }
        }

        if (child.name === `R_Marking` && product === "qcueb") {
          child.material.color = config.materialValuesExtra[color]
        }
        if (child.name === `strapCarrier` || (child.name === "pSphere" && product === "slmicro")) {
          child.material.color = config.materialValuesExtra[color]
        }

        if (child.name === `BackLogo` && product === "qce") {
          child.material.color = config.materialValuesExtra[color]
        }

        if (
          child.name == `Case_Remeshed-Black` ||
          child.name == `Metal_Front_Plate_Remesh-Black` ||
          child.name == `Metal_Front_Plate_Remesh_2-Black` ||
          child.name == `Lid_remesh-Black` ||
          child.name == "Button_Simplified_remesh-Black" ||
          child.name == "Button_Simplified_Remesh_2-Black" ||
          child.name == "Button_Simplified_Remesh-Black"
        ) {
          if (!(child.material instanceof THREE.MeshPhysicalMaterial)) {
            child.material = new THREE.MeshPhysicalMaterial({})
            const flakes = new TextureLoader().load("flakes.jpg")
            flakes.wrapS = RepeatWrapping
            flakes.flipY = false
            // flakes.encoding = 3001
            flakes.wrapT = RepeatWrapping
            flakes.repeat.set(15, 15)
            child.material.normalMap = flakes
          }

          child.material.toneMapped = false
          child.material.color = config.materialValues[color]
          child.material.roughness = config.cupRoughness[color]
          child.material.metalness = config.materialMetalness[color]
          child.material.normalScale.set(config.normal[color], config.normal[color])
          child.material.clearcoat = config.clearcoat[color]
          child.material.clearcoatRoughness = 0.1
          child.material.reflectivity = 0.6
          child.material.envMapIntensity = config.envmap[color]
          child.material.dithering = true
          child.material.iridescenceIOR = 1.43
          child.material.toneMapped = false
          child.material.iridescence = config.materialIridescence[color]
          child.material.transparent = true
          child.material.iridescenceThicknessRange = [0, config.materialThickness[color]]
          child.material.specularTint = config.sheenColor[color]
          child.material.specularColor = config.sheenColor[color]
          child.material.specularIntensity = config.sheenIntensity[color]
          if (child.name === "Metal_Front_Plate_Remesh-Black" || child.name === "Metal_Front_Plate_Remesh_2-Black") {
            // Check if the texture has already been loaded and stored on the child
            if (!child._gradientTexture) {
              // Only load if it hasn't been loaded already
              child._gradientTexture = new TextureLoader().load("gradientUOEB.jpg")
              child._gradientTexture.flipY = false
            }

            // Apply or remove the texture based on the color condition
            if (color === 7) {
              child.material.map = child._gradientTexture
              child.material.needsUpdate = true // Inform Three.js that the material has changed
            } else {
              child.material.map = null // Set the map to null to remove the texture
              child.material.needsUpdate = true // Inform Three.js to update the material
            }
          }

          if ((child.name == "Button_Simplified_Remesh_2-Black" || child.name == "Button_Simplified_Remesh-Black") && color == 8) {
            child.material.color = config.materialValuesCap[color]
            child.material.metalness = 1
          }
          if ((child.name == "Button_Simplified_Remesh_2-Black" || child.name == "Button_Simplified_Remesh-Black") && color == 7) {
            child.material.color = config.materialValuesCap[color]
            child.material.metalness = 0
          }
        }
        if (child.name === "Main_Shell_Remesh_2-Black" || child.name === "Main_Shell_Remesh-Black") {
          child.material = new THREE.MeshStandardMaterial({})
          child.material.color = config.materialValuesExtraBud[color]
          child.material.roughness = config.cupRoughness[color]
          // child.material.metalness = 1
        }
        if (child.name === "Top_Cap_Simplified_Remesh-Black") {
          child.material.color = config.materialValuesNeck[color]
        }

        if (config.dynamicColor.includes(child.name)) {
          child.material.toneMapped = false
          child.material.envMapIntensity = config.envmap[color]
          child.material.dithering = true
          child.material.color = config.materialValues[color]
          if (child.name == `duran_headband_GRP_right_hinge` || child.name == `holes`) {
            child.material.color = config.materialValuesExtra[color]
          }

          if (product === "qcueb" && (child.name == `case` || child.name == `inner001`)) {
            const newMat = new MeshPhysicalMaterial({})
            child.material = newMat

            child.material.envMapIntensity = config.envmap[color]
            child.material.dithering = true
            // child.material.iridescence = 1
            child.material.roughness = config.cupRoughness[color]
            // child.material.specularTint = config.sheenColor[color]
            child.material.specularIntensity = config.sheenIntensity[color]
            child.material.color = config.materialValues[color]
            child.material.specularColor = config.sheenColor[color]
            // child.material.metalness = 0.1
          }
          if (child.name == `padX` || child.name == `padL`) {
            const newMatPad = new MeshPhysicalMaterial({})

            child.material = newMatPad
            const texture = textureMap[config.dynamicTexturesName[child.name]]
            child.material.map = texture
            child.material.dithering = true
            // texture.wrapS = RepeatWrapping
            // texture.wrapT = RepeatWrapping
            // texture.flipY = false
            child.material.envMapIntensity = config.envmap2[color]
            child.material.roughness = config.cupRoughness2[color]
            child.material.specularColor = config.sheenColor[color]
            child.material.specularTint = config.sheenColor[color]
            child.material.specularIntensity = config.sheenIntensity2[color]
          }
          // if (child.name == `Metal_Front_Plate_Remesh-Black`) {
          //   child.material.color = config.materialValuesExtraOuter[color]
          // }
          // if (child.name == `Metal_Front_Plate_Remesh_2-Black`) {
          //   child.material.color = config.materialValuesExtraOuter[color]
          // }
          if (child.name == `Inside_Lid_remesh-Black` || child.name == `Tray_Remeshed-Black` || child.name == "Main_Shell_Remesh-Black") {
            child.material.color = config.materialValuesExtra[color]
          }
          if (child.name == `Arm_R_Remesh`) {
            child.material.color = config.materialValuesExtra[color]
            child.material.roughness = config.roughnessScrim[color]
            if (color === 4) {
              child.material.normalScale.set(0.1, -0.1)
            } else {
              child.material.normalScale.set(0.86, -0.86)
            }

            // child.material.normalMap = null
          }
          if (child.name == `L_Earcup_Remesh` || child.name == `R_Earcup_Remesh` || child.name == `Headband_Top_Remesh`) {
            child.material = new MeshPhysicalMaterial({})

            child.material.envMapIntensity = config.envmap[color]
            child.material.dithering = true
            child.material.roughness = config.cupRoughness[color]
            child.material.side = DoubleSide
            child.material.specularColor = config.sheenColor[color]
            child.material.color = config.materialValues[color]
            // child.material.reflectivity = 1
            // child.material.specularTint = config.sheenColor[color]
            child.material.specularIntensity = config.sheenIntensity[color]
          }

          if (child.name == `loneScrims`) {
            child.material.color = config.materialInnerScrim[color]
          }
        }
        if (product === "uoeb") {
          if (child.name.includes("Bose_Logo_OG-Black")) {
            child.material.metalness = config.logoMetalness[color]
          }
        }
        if (config.logoGroup.includes(child.name)) {
          if (child.name.includes("sb900logo")) {
            if (child.name == `sb900logo-${config.textureNames[color]}`) {
              child.visible = true
            } else {
              child.visible = false
            }
            return
          }

          if (child.name.includes("sb900logo")) {
            if (child.name == `sb900logo-${config.textureNames[color]}`) {
              child.visible = true
            } else {
              child.visible = false
            }
            return
          }
          child.material.color = config.logoColor[color]
          child.material.metalness = config.logoMetalness[color]
          if (product === "qce2") {
            child.material.roughness = config.logoRoughness[color]
            return
          }
        }
      })

      setLoaded(true)
    }, [color])

    return (
      <primitive object={scene} dispose={null} scale={config.scale} position={config.position} rotation={config.rotation} visible={true} />
    )
  }
  function Loader() {
    const { progress } = useProgress()
    return (
      <Html center style={{ fontSize: "34px", width: "100px", height: "100px" }}>
        <CircularProgressbar
          styles={buildStyles({
            strokeLinecap: "butt",
            textColor: "rgb(36, 36, 36)",
            pathColor: "rgb(36, 36, 36)",
            trailColor: "transparent",
          })}
          value={progress.toFixed(0)}
          text={`${progress.toFixed(0)}%`}
        />
      </Html>
    )
  }

  const Shadow = () => {
    const { scene } = useGLTF(config.shadow)
    scene.traverse((child) => {
      if (child.isMesh) {
        child.material.needsUpdate = true

        child.material.transparent = true // Enable transparency for blending
        // child.material.blending = THREE.MultiplyBlending
      }
    })
    return <primitive object={scene} scale={config.shadowScale} position={config.position} rotation={config.rotation} />
  }
  const CameraControls = () => {
    let distance
    let offset
    const {
      camera,
      gl: { domElement },
    } = useThree()

    useFrame(() => {
      if (update) {
        // console.log(camera.position)
        controls.current.update()
      }

      if (config.wide) {
        if (idle.idle) {
          controls.current.target.x = idle.target.x
          return
        }
        if (product === "edelman") {
          distance = camera.position.distanceTo(controls.current.target) / 10
          offset = camera.position.x / 5
          if (offset < -0.01 || offset > 0.01) {
            controls.current.target.x = camera.position.x / (7.2 * distance)
          }
          return
        }
        distance = camera.position.distanceTo(controls.current.target) / 10
        offset = camera.position.x / 5
        if (offset < -0.01 || offset > 0.01) {
          controls.current.target.x = camera.position.x / (4.2 * distance)
        }
      }
    })
    return (
      <orbitControls
        ref={controls}
        args={[camera, domElement]}
        enableZoom={noZoom ? false : true}
        enableKeys={true}
        autoRotate={false}
        enablePan={false}
        target={idle.target}
        rotateSpeed={config.cameraSpeed}
        enableDamping={true}
        dampingFactor={0.05}
        maxDistance={30}
        minDistance={config.minDistance}
        maxPolarAngle={product === "qcuhp" ? Math.PI : Math.PI / 2}
      />
    )
  }
  // const noPorterLocales = [
  //   "de-DE",
  //   "en-CA",
  //   "en-GB",
  //   "en-US",
  //   "en-AE",
  //   "en-AU",
  //   "en-HK",
  //   "en-IN",
  //   "en-IE",
  //   "en-NZ",
  //   "en-SG",
  //   "fr-CA",
  //   "fr-CH",
  //   "fr-LU",
  //   "fr-BE",
  //   "fr-FR",
  //   "de-AT",
  //   "de-CH",
  //   "zh-HK",
  //   "zh-TW",
  //   "zh-CN",
  //   "da-DK",
  //   "nl-NL",
  //   "nl-BE",
  //   "fi-FI",
  //   "hu-HU",
  //   "it-IT",
  //   "it-CH",
  //   "ja-JP",
  //   "nb-NO",
  //   "pl-PL",
  //   "es-ES",
  //   "es-MX",
  //   "es-AR",
  //   "es-CL",
  //   "es-CO",
  //   "es-PE",
  //   "sv-SE",
  //   "vi-VN",
  // ]
  // EU release
  const noPorterLocales = ["en-AE", "en-HK", "en-IN", "en-SG", "zh-HK", "zh-TW", "zh-CN", "vi-VN"]
  const CypressLocales = ["es-ES", "hu-HU"]
  const moonStonesLocalesQcueb = ["en-US", "ja-JP", "en-CA", "fr-CA"]
  const noQC45Locales = ["es-AR", "es-CL", "es-CO", "es-PE"]
  // const noSandStoneLocales = ["es-MX", "es-CL", "es-CO", "es-AR", "es-PE", "ja-JP", "hu-HU", "es-ES", "en-AE", "zh-TW", "zh-HK", "en-HK"]
  const noSandStoneLocales = [""]
  const flexLocales = [
    "en-US",
    "es-MX",
    "zh-TW",
    "zh-HK",
    "en-CA",
    "fr-CA",
    "en-AU",
    "en-NZ",
    "en-IN",
    "en-SG",
    "en-AE",
    "en-HK",
    "de-AT",
    "de-CH",
    "de-DE",
    "da-DK",
    "en-GB",
    "en-IE",
    "es-ES",
    "fr-BE",
    "fr-CH",
    "fr-FR",
    "fr-LU",
    "it-IT",
    "it-CH",
    "hu-HU",
    "nl-NL",
    "nl-BE",
    "nb-NO",
    "ja-JP",
    "pl-PL",
    "fi-FI",
    "sv-SE",
  ]
  const diamondLocales = [
    "en-US",
    "en-CA",
    "fr-CA",
    "fr-FR",
    "de-DE",
    "en-GB",
    "nl-BE",
    "fr-BE",
    "en-IE",
    "it-IT",
    "nl-NL",
    "de-AT",
    "sv-SE",
    "en-AU",
    "en-NZ",
    "ja-JP",
    "zh-HK",
    "zh-TW",
    "en-HK",
    "de-CH",
    "fr-CH",
    "it-CH",
    "da-DK",
    "es-ES",
    "fr-LU",
    "fr-LU",
    "it-IT",
    "nb-NO",
    "pl-PL",
    "fi-FI",
    "sv-SE",
    "hu-HU",
  ]

  const lunarLocales = [
    "en-US",
    "ja-JP",
    "en-CA",
    "fr-CA",
    "de-DE",
    "fr-FR",
    "en-GB",
    "en-AU",
    "en-NZ",
    "de-AT",
    "de-CH",
    "fr-CH",
    "it-CH",
    "da-DK",
    "en-HK",
    "zh-HK",
    "zh-TW",
    "en-IE",
    "es-ES",
    "nl-BE",
    "nl-NL",
    "fr-BE",
    "fr-LU",
    "it-IT",
    "nb-NO",
    "pl-PL",
    "fi-FI",
    "sv-SE",
    "hu-HU",
  ]
  const lunarLocalesU = [
    "en-US",
    "en-CA",
    "fr-CA",
    "fr-FR",
    "de-DE",
    "en-GB",
    // "nl-BE",
    // "fr-BE",
    // "en-IE",
    // "it-IT",
    // "nl-NL",
    // "de-AT",
    // "sv-SE",
    // "en-AU",
    // "en-NZ",
    // "ja-JP",
  ]
  const moonStonesLocales = [
    "en-US",
    "zh-TW",
    "zh-HK",
    "en-CA",
    "ja-JP",
    "fr-CA",
    "de-DE",
    "fr-FR",
    "en-GB",
    "en-AU",
    "en-NZ",
    "de-AT",
    "de-CH",
    "fr-CH",
    "it-CH",
    "da-DK",
    "en-HK",
    "zh-HK",
    "zh-TW",
    "en-IE",
    "es-ES",
    "nl-BE",
    "nl-NL",
    "fr-BE",
    "fr-LU",
    "it-IT",
    "nb-NO",
    "pl-PL",
    "fi-FI",
    "sv-SE",
    "hu-HU",
  ]

  const carltonRelease = [
    "en-US",
    "en-CA",
    "fr-CA",
    // "fr-FR",
    // "de-DE",
    // "en-GB",
    "nl-BE",
    "fr-BE",
    "en-IE",
    "it-IT",
    "de-AT",
    "nl-nL",
    "fr-FR",
    "de-DE",
    "en-GB",
    "de-CH",
    "fr-CH",
    "it-CH",
    "fi-FI",
    "da-DK",
    "nb-NO",
    "pl-PL",
    "sv-SE",
    "es-ES",
    "fr-LU",
    "hu-HU",
    "zh-HK",
    "en-HK",
    "zh-TW",
    "ja-JP",

    // "nl-NL",
    // "de-AT",
    // "sv-SE",
    "en-AU",
    "en-NZ",
    // "ja-JP",
  ]

  const carltonReleaseSand = [
    "en-US",
    "en-CA",
    "fr-CA",
    "nl-BE",
    "fr-BE",
    "en-IE",
    "it-IT",
    "de-AT",
    "nl-nL",
    "de-CH",
    "fr-CH",
    "it-CH",
    "fi-FI",
    "da-DK",
    "nb-NO",
    "pl-PL",
    "sv-SE",
    "es-ES",
    "fr-LU",
    "hu-HU",
    "zh-HK",
    "en-HK",
    "zh-TW",
    "ja-JP",
    "fr-FR",
    "de-DE",
    "en-GB",
    // "nl-BE",
    // "fr-BE",
    // "en-IE",
    // "it-IT",
    // "nl-NL",
    // "de-AT",
    // "sv-SE",
    "en-AU",
    "en-NZ",
    // "ja-JP",
  ]

  const uoebNew = ["en-US", "en-CA", "fr-CA"]

  useEffect(() => {
    if (product === "qcheadphones") {
      const colorOptions = document.querySelectorAll(".colorOptions")
      const len = colorOptions[0].children.length

      if (len >= 3) {
        // colorOptions[0].children[len - 1].style.display = "none" // Last child
        // colorOptions[0].children[len - 2].style.display = "none" // Second last child
        // colorOptions[0].children[len - 3].style.display = "none" // Third last child
      }
      if (carltonRelease.includes(i18n.language)) {
        // Apply display:none to the fifth and seventh child
        if (colorOptions[0].children[4]) {
          colorOptions[0].children[4].style.display = "block" // Fifth child
        }
        if (colorOptions[0].children[5]) {
          colorOptions[0].children[5].style.display = "block" // Fifth child
        }
        if (colorOptions[0].children[6]) {
          colorOptions[0].children[6].style.display = "none" // Seventh child
        }
      }

      if (carltonRelease.includes(i18n.language) && carltonReleaseSand.includes(i18n.language)) {
        // Apply display:none to the fifth and seventh child
        if (colorOptions[0].children[4]) {
          colorOptions[0].children[4].style.display = "block" // Fifth child
        }
        if (colorOptions[0].children[5]) {
          colorOptions[0].children[5].style.display = "block" // Fifth child
        }
        if (colorOptions[0].children[6]) {
          colorOptions[0].children[6].style.display = "block" // Seventh child
        }
      }
      // Apply display:none to the last three children
    }
  }, [product, i18n.language])

  useEffect(() => {
    if (product === "uoeb") {
      const colorOptions = document.querySelectorAll(".colorOptions")

      // Existing code for hiding elements based on locale
      if (!diamondLocales.includes(i18n.language)) {
        if (colorOptions[0].children[2]) {
          colorOptions[0].children[2].style.display = "none" // Fifth child
        }
        if (colorOptions[0].children[3]) {
          colorOptions[0].children[3].style.display = "none" // Seventh child
        }
      } else {
        colorOptions[0].children[2].style.display = "none" // Fifth child
        colorOptions[0].children[3].style.display = "block" // Seventh child
      }

      if (!lunarLocalesU.includes(i18n.language)) {
        colorOptions[0].children[4].style.display = "none"
      }
      if (moonStonesLocales.includes(i18n.language)) {
        colorOptions[0].children[2].style.display = "block"
      }

      if (!uoebNew.includes(i18n.language)) {
        ;["Carbon", "Sunset", "Lilac", "Sand"].forEach((id) => {
          const element = Array.from(colorOptions[0].children).find((child) => child.id === id)
          if (element) {
            element.style.display = "none"
          }
        })
      }

      // Reorder elements by ID after all visibility changes
      const reorderIds = ["Black", "White", "Diamond", "Carbon", "Sunset", "Lilac", "Lunar", "Sand", "Blue"]
      const reorderedElements = reorderIds
        .map((id) => Array.from(colorOptions[0].children).find((child) => child.id === id))
        .filter(Boolean) // Filter out any nulls in case of missing elements

      // Append reordered elements to the container
      reorderedElements.forEach((element) => {
        colorOptions[0].appendChild(element) // Move each element to the end in the new order
      })
    }
  }, [product, i18n.language])

  useEffect(() => {
    if (product === "qcuhp" || product === "qcueb") {
      const colorOptions = document.querySelectorAll(".colorOptions")

      if (!diamondLocales.includes(i18n.language)) {
        // Apply display:none to the fifth and seventh child
        if (colorOptions[0].children[3]) {
          colorOptions[0].children[3].style.display = "none" // Fifth child
        }
        if (colorOptions[0].children[4]) {
          colorOptions[0].children[4].style.display = "none" // Seventh child
        }
      } else {
        // Apply display:none to the last three children
        // const len = colorOptions[0].children.length
        colorOptions[0].children[3].style.display = "none" // Fifth child
        colorOptions[0].children[4].style.display = "block" // Seventh child
        // if (len >= 3) {
        //   // colorOptions[0].children[len - 1].style.display = "none" // Last child
        //   colorOptions[0].children[len - 2].style.display = "none" // Second last child
        //   // colorOptions[0].children[len - 3].style.display = "none" // Third last child
        // }
      }
      if (lunarLocales.includes(i18n.language)) {
        colorOptions[0].children[3].style.display = "block" // Seventh child
      }
    }
  }, [product, i18n.language])

  const getColorItems = () => {
    if ((product === "qce2" && noPorterLocales.includes(i18n.language)) || (product === "qc45" && noQC45Locales.includes(i18n.language))) {
      const remove = [2, 3]
      const indexSet = new Set(remove)
      const array = config.colorNames
      const filteredColorNames = array.filter((value, i) => !indexSet.has(i))
      return filteredColorNames
    }
    if (product === "qcuhp" && noSandStoneLocales.includes(i18n.language)) {
      const remove = [2]
      const indexSet = new Set(remove)
      const array = config.colorNames
      const filteredColorNames = array.filter((value, i) => !indexSet.has(i))
      return filteredColorNames
    }
    if (product === "flex" && !flexLocales.includes(i18n.language)) {
      const remove = [4]
      const indexSet = new Set(remove)
      const array = config.colorNames
      const filteredColorNames = array.filter((value, i) => !indexSet.has(i))
      return filteredColorNames
    }
    // if (product === "qcueb" && !moonStonesLocalesQcueb.includes(i18n.language)) {
    //   const remove = [2]
    //   const indexSet = new Set(remove)
    //   const array = config.colorNames
    //   const filteredColorNames = array.filter((value, i) => !indexSet.has(i))
    //   return filteredColorNames
    // }
    // if (product === "qcheadphones" && !moonStonesLocales.includes(i18n.language)) {
    //   const remove = [2]
    //   const indexSet = new Set(remove)
    //   const array = config.colorNames
    //   const filteredColorNames = array.filter((value, i) => !indexSet.has(i))
    //   return filteredColorNames
    // }
    return config.colorNames
  }
  const colorList = getColorItems().map((currElement, index) => (
    <div
      key={index}
      id={config.colorNames[index]}
      tabIndex={0}
      onClick={() => {
        idleModel(controls.current.target)
        setColor(index)
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          idleModel(controls.current.target)
          setColor(index)
        }
      }}
      className={"colorOuter"}
      style={{
        display: hide.includes(index + 1) ? "none" : "block",
        border: color == index ? "solid 1px rgb(41, 41, 41)" : "solid 1px transparent",
      }}>
      <div style={{ backgroundColor: config.colorValues[index] }} className={"colorInner"}></div>
    </div>
  ))
  const getDpr = () => {
    if (!isMobile) {
      return catalina ? 1 : 1
    } else return 2
  }
  const renderHandOverlay = () => {
    if (loaded) {
      return (
        <div className={"handInstruction"} style={{ opacity: loaded ? "1" : "none" }}>
          <svg viewBox="0 0 25 36" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(-11 -4)" fill="none" fillRule="evenodd">
              <path d="M0 0h44v44H0z" fill="#fff" fillOpacity="0" />
              <g transform="translate(11 3)">
                <path
                  d="M8.733 11.165c.04-1.108.766-2.027 1.743-2.307a2.54 2.54 0 0 1 .628-.089c.16 0 .314.017.463.044 1.088.2 1.9 1.092 1.9 2.16v8.88h1.26c2.943-1.39 5-4.45 5-8.025a9.01 9.01 0 0 0-1.9-5.56l-.43-.5c-.765-.838-1.683-1.522-2.712-2-1.057-.49-2.226-.77-3.46-.77s-2.4.278-3.46.77c-1.03.478-1.947 1.162-2.71 2l-.43.5a9.01 9.01 0 0 0-1.9 5.56 9.04 9.04 0 0 0 .094 1.305c.03.21.088.41.13.617l.136.624c.083.286.196.56.305.832l.124.333a8.78 8.78 0 0 0 .509.953l.065.122a8.69 8.69 0 0 0 3.521 3.191l1.11.537v-9.178z"
                  fill="#e4e4e4"
                  fillOpacity=".5"
                />
                <path
                  d="M22.94 26.218l-2.76 7.74c-.172.485-.676.8-1.253.8H12.24c-1.606 0-3.092-.68-3.98-1.82-1.592-2.048-3.647-3.822-6.11-5.27-.095-.055-.15-.137-.152-.23-.004-.1.046-.196.193-.297.56-.393 1.234-.6 1.926-.6a3.43 3.43 0 0 1 .691.069l4.922.994V10.972c0-.663.615-1.203 1.37-1.203s1.373.54 1.373 1.203v9.882h2.953c.273 0 .533.073.757.21l6.257 3.874c.027.017.045.042.07.06.41.296.586.77.426 1.22M4.1 16.614c-.024-.04-.042-.083-.065-.122a8.69 8.69 0 0 1-.509-.953c-.048-.107-.08-.223-.124-.333l-.305-.832c-.058-.202-.09-.416-.136-.624l-.13-.617a9.03 9.03 0 0 1-.094-1.305c0-2.107.714-4.04 1.9-5.56l.43-.5c.764-.84 1.682-1.523 2.71-2 1.058-.49 2.226-.77 3.46-.77s2.402.28 3.46.77c1.03.477 1.947 1.16 2.712 2l.428.5a9 9 0 0 1 1.901 5.559c0 3.577-2.056 6.636-5 8.026h-1.26v-8.882c0-1.067-.822-1.96-1.9-2.16-.15-.028-.304-.044-.463-.044-.22 0-.427.037-.628.09-.977.28-1.703 1.198-1.743 2.306v9.178l-1.11-.537C6.18 19.098 4.96 18 4.1 16.614M22.97 24.09l-6.256-3.874c-.102-.063-.218-.098-.33-.144 2.683-1.8 4.354-4.855 4.354-8.243 0-.486-.037-.964-.104-1.43a9.97 9.97 0 0 0-1.57-4.128l-.295-.408-.066-.092a10.05 10.05 0 0 0-.949-1.078c-.342-.334-.708-.643-1.094-.922-1.155-.834-2.492-1.412-3.94-1.65l-.732-.088-.748-.03a9.29 9.29 0 0 0-1.482.119c-1.447.238-2.786.816-3.94 1.65a9.33 9.33 0 0 0-.813.686 9.59 9.59 0 0 0-.845.877l-.385.437-.36.5-.288.468-.418.778-.04.09c-.593 1.28-.93 2.71-.93 4.222 0 3.832 2.182 7.342 5.56 8.938l1.437.68v4.946L5 25.64a4.44 4.44 0 0 0-.888-.086c-.017 0-.034.003-.05.003-.252.004-.503.033-.75.08a5.08 5.08 0 0 0-.237.056c-.193.046-.382.107-.568.18-.075.03-.15.057-.225.1-.25.114-.494.244-.723.405a1.31 1.31 0 0 0-.566 1.122 1.28 1.28 0 0 0 .645 1.051C4 29.925 5.96 31.614 7.473 33.563a5.06 5.06 0 0 0 .434.491c1.086 1.082 2.656 1.713 4.326 1.715h6.697c.748-.001 1.43-.333 1.858-.872.142-.18.256-.38.336-.602l2.757-7.74c.094-.26.13-.53.112-.794s-.088-.52-.203-.76a2.19 2.19 0 0 0-.821-.91"
                  fill="#000"
                  fillOpacity=".6"
                />
                <path
                  d="M22.444 24.94l-6.257-3.874a1.45 1.45 0 0 0-.757-.211h-2.953v-9.88c0-.663-.616-1.203-1.373-1.203s-1.37.54-1.37 1.203v16.643l-4.922-.994a3.44 3.44 0 0 0-.692-.069 3.35 3.35 0 0 0-1.925.598c-.147.102-.198.198-.194.298.004.094.058.176.153.23 2.462 1.448 4.517 3.22 6.11 5.27.887 1.14 2.373 1.82 3.98 1.82h6.686c.577 0 1.08-.326 1.253-.8l2.76-7.74c.16-.448-.017-.923-.426-1.22-.025-.02-.043-.043-.07-.06z"
                  fill="#fff"
                />
                <g transform="translate(0 .769)">
                  <mask id="a" fill="#fff" />
                  <path
                    d="M23.993 24.992a1.96 1.96 0 0 1-.111.794l-2.758 7.74c-.08.22-.194.423-.336.602-.427.54-1.11.87-1.857.872h-6.698c-1.67-.002-3.24-.633-4.326-1.715-.154-.154-.3-.318-.434-.49C5.96 30.846 4 29.157 1.646 27.773c-.385-.225-.626-.618-.645-1.05a1.31 1.31 0 0 1 .566-1.122 4.56 4.56 0 0 1 .723-.405l.225-.1a4.3 4.3 0 0 1 .568-.18l.237-.056c.248-.046.5-.075.75-.08.018 0 .034-.003.05-.003.303-.001.597.027.89.086l3.722.752V20.68l-1.436-.68c-3.377-1.596-5.56-5.106-5.56-8.938 0-1.51.336-2.94.93-4.222.015-.03.025-.06.04-.09.127-.267.268-.525.418-.778.093-.16.186-.316.288-.468.063-.095.133-.186.2-.277L3.773 5c.118-.155.26-.29.385-.437.266-.3.544-.604.845-.877a9.33 9.33 0 0 1 .813-.686C6.97 2.167 8.31 1.59 9.757 1.35a9.27 9.27 0 0 1 1.481-.119 8.82 8.82 0 0 1 .748.031c.247.02.49.05.733.088 1.448.238 2.786.816 3.94 1.65.387.28.752.588 1.094.922a9.94 9.94 0 0 1 .949 1.078l.066.092c.102.133.203.268.295.408a9.97 9.97 0 0 1 1.571 4.128c.066.467.103.945.103 1.43 0 3.388-1.67 6.453-4.353 8.243.11.046.227.08.33.144l6.256 3.874c.37.23.645.55.82.9.115.24.185.498.203.76m.697-1.195c-.265-.55-.677-1.007-1.194-1.326l-5.323-3.297c2.255-2.037 3.564-4.97 3.564-8.114 0-2.19-.637-4.304-1.84-6.114-.126-.188-.26-.37-.4-.552-.645-.848-1.402-1.6-2.252-2.204C15.472.91 13.393.232 11.238.232A10.21 10.21 0 0 0 5.23 2.19c-.848.614-1.606 1.356-2.253 2.205-.136.18-.272.363-.398.55C1.374 6.756.737 8.87.737 11.06c0 4.218 2.407 8.08 6.133 9.842l.863.41v3.092l-2.525-.51c-.356-.07-.717-.106-1.076-.106a5.45 5.45 0 0 0-3.14.996c-.653.46-1.022 1.202-.99 1.983a2.28 2.28 0 0 0 1.138 1.872c2.24 1.318 4.106 2.923 5.543 4.772 1.26 1.62 3.333 2.59 5.55 2.592h6.698c1.42-.001 2.68-.86 3.134-2.138l2.76-7.74c.272-.757.224-1.584-.134-2.325"
                    fill="#000"
                    mask="url(#a)"
                    fillOpacity=".05"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>
      )
    }
  }
  useEffect(() => {
    if (locale) {
      i18n.changeLanguage(locale)
    }
  }, [analytics, locale, r])

  const [currentLocale, setCurrentLocale] = useState("en-US")
  const localeLang = `${currentLocale}`

  useEffect(() => {
    setCurrentLocale(`${window.location.pathname}`)
  }, [])

  const getFontFamily = (fontLocale) => {
    switch (fontLocale) {
      case "/ja-JP":
        return `BoseText-JP`
      case "/zh-HK":
        return `BoseText-ZH`
      case "/zh-TW":
        return `BoseText-ZH`
      default:
        return `BoseText`
    }
  }

  return (
    <>
      <div className={"container"} style={{ fontFamily: getFontFamily(localeLang) }}>
        {renderHandOverlay()}
        <div
          style={{ display: closeButton == "true" ? "block" : "none" }}
          className={"closeButton"}
          onClick={() => {
            if (window !== window.parent) {
              window.parent.postMessage("360_close", "*")
            } else {
              window.location.href =
                "http://www.bose.com/en_us/products/speakers/home_theater/bose-smart-soundbar-900.html#v=bose_smart_soundbar_900_white"
            }
          }}>
          <img className={"closeIcon"} src="/close.svg" />
        </div>
        <div className={"colorPicker"} style={{ bottom: reseller ? "30px" : "5px" }}>
          <h3>
            {`${t("general.color")}:`} <strong>{t(`${product}.colors.${color}`)}</strong>
          </h3>
          <div className={"colorOptions"}>{colorList}</div>
        </div>
        <PrivacyOverlay />
        <LanguageOverlay />
        {/* <InView
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
          }}
          onChange={setInView}
        > */}
        <Canvas
          ref={canvas}
          gl={{ antialias: true, alpha: false }}
          onCreated={(state) => {
            state.gl.setClearColor(0xffffff) // Sets background to white
          }}
          dpr={getDpr()}
          flat
          camera={{ fov: 60, position: isMobile ? config.cameraPositionMobile : config.cameraPositionDesktop }}>
          <CameraControls />
          <ambientLight intensity={config.ambient[color]} />

          <Suspense fallback={<Loader />}>
            <LoadedModel />
            <Shadow />

            <Environment files={config.hdri} />

            <EffectComposer disableNormalPass autoClear={false}>
              <N8AO halfRes color="black" ada aoRadius={0.8} intensity={config.ssaoIntensity[color]} aoSamples={12} denoiseSamples={5} />
              <ToneMapping
                adaptive={false}
                resolution={256}
                maxLuminance={4}
                whitePoint={6.0}
                middleGrey={0.2}
                mode={ToneMappingMode.ACES_FILMIC}
                minLuminance={0.01}
                averageLuminance={1.0}
                adaptationRate={0}
              />
            </EffectComposer>
          </Suspense>
        </Canvas>
        {/* </InView> */}
        <div style={{ position: "relative", width: "100%" }}>
          <ResellerBar />
        </div>
      </div>
    </>
  )
}
